:root {
    --primary-color: #6fe9c0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div,
a,
header,
section,
main,
p {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.editor-container {
    width: 90vw;
    height: 80vh;
}

a,
a:visited {
    color: inherit;
}

body {
    background-color: #181818;
    color: #fff;
}
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header__bg {
    background-color: #212121;
}

.header {
    padding: 0 1.5rem;
    margin-bottom: 1rem;
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
}

.header a {
    text-decoration: none;
}

.header a,
.header a:visited {
    color: inherit;
}

.header__content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__user {
    flex-shrink: 0;
    display: flex;
}

.header__content nav ul {
    list-style-type: none;
    display: flex;
}

.header__content nav {
    width: 100%;
}

.header__li {
    margin-left: 1rem;
}

.header__nav-link {
    font-size: 0.9rem;
    padding: 0px 1rem;
    display: block;
    border-radius: 17px;
}

.header__nav-link:hover {
    background-color: #ccc;
    color: #000 !important;
}

.header__logo a {
    font-weight: bold;
    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: #4acdf5 !important;
}

.nav__new-link {
    background-color: var(--primary-color);
    color: #000 !important;
}

.container {
    padding: 1rem 1.5rem;
    max-width: 1250px;
    margin: 0 auto;
}

.warning__input {
    background: inherit;
    border: none;
    border: 1px solid #f2f2f2;
    color: #fff;
    margin-right: 10px;
}

.warning__submit {
    background-color: var(--primary-color);
    color: #000 !important;
    border-radius: 17px;
    padding: 0.5rem 1rem;
}

.warning__no-name {
    min-width: 20rem;
    width: 36%;
    margin: 0 auto;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    font-size: 0.8rem;
    color: #bfb6b6;
}

.footer a {
    color: #f2f2f2;
}

.footer__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer__author {
    text-align: center;
}

.footer::before {
    display: block;
    content: '';
    border-top: 0.5px solid #ccc;
    width: 100%;
    position: absolute;
}

.no-data {
    text-align: center;
}

.recent-snippet {
    border: 1px solid;
    padding: 1rem;
    display: flex;
    border-radius: 10px;
    width: 30%;
    min-width: 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0.5rem 1rem;
    flex-direction: column;
    justify-content: space-between;
}

.recent-snippet li, .recent-snippet li code {
    font-size: 1rem;
}

.recent-snippet__link {
    text-decoration: none;
}

.recent-snippet ul {
    list-style-type: none;
    padding: 0;
}

.recent-snippets {
    display: flex;
    flex-wrap: wrap;
}
.user-avatar {
    background-color: #c86565;
    border-radius: 100%;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    font-weight: 600;
    text-align: center;
    background-size: 100%;
}

.header__user .user-avatar:hover {
    cursor: pointer;
}

.shake {
    transform: translate3d(0, 0, 0);
    animation-name: shake-animation;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes shake-animation {
    0.7%, 6% {
        transform: translate3d(-5px, 0, 0);
    }

    1.3%, 5% {
        transform: translate3d(5px, 0, 0);
    }

    2%, 3.3%, 5% {
        transform: translate3d(-5px, 0, 0);
    }

    2.2%, 4% {
        transform: translate3d(5px, 0, 0);
    }

    6.1% {
        transform: translate3d(0px, 0, 0);
    }
    100% {
        transform: translate3d(0px, 0, 0);
    }
}